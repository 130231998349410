import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import { LayoutProvider } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  typestyle: 'Display' | 'Heading' | 'Body';
  lineHeight: number;
  setLineHeight: React.Dispatch<React.SetStateAction<number>>;
  letterSpacing: number;
  setLetterSpacing: React.Dispatch<React.SetStateAction<number>>;
  alignment: 'left' | 'center';
  setAlignment: React.Dispatch<React.SetStateAction<'left' | 'center'>>;
  handleOnReset: () => void;
}

const Toolbar = ({
  typestyle,
  lineHeight,
  setLineHeight,
  letterSpacing,
  setLetterSpacing,
  alignment,
  setAlignment,
  handleOnReset
}: IProps) => {
  const [letterSpacingDisplay, setLetterSpacingDisplay] = useState(
    `${letterSpacing}`
  );
  const [lineHeightDisplay, setLineHeightDisplay] = useState(`${lineHeight}`);
  const [showLetterSpacingError, setShowLetterSpacingError] = useState(false);
  const [showLineHeightError, setShowLineHeightError] = useState(false);

  const onLetterSpacingChange = (value: string) => {
    const numValue = parseFloat(value);

    if (typeof numValue !== 'number') {
      setLetterSpacingDisplay(value);
      return;
    }

    const isValidValue = numValue >= -0.12 && numValue <= 0.16;

    setLetterSpacingDisplay(value);

    if (isValidValue) {
      setShowLetterSpacingError(false);
      setLetterSpacing(numValue);
      return;
    }

    setShowLetterSpacingError(true);
  };

  const onLineHeightChange = (value: string) => {
    const numValue = parseFloat(value);

    if (typeof numValue !== 'number') {
      setLineHeightDisplay(value);
      return;
    }

    const isValidValue = numValue >= 70 && numValue <= 150;

    setLineHeightDisplay(value);

    if (isValidValue) {
      setShowLineHeightError(false);
      setLineHeight(numValue);
      return;
    }

    setShowLineHeightError(true);
  };

  const handleBlurLetterSpacing = () => {
    if (letterSpacingDisplay === '' || letterSpacingDisplay === '-') {
      setLetterSpacingDisplay(`${letterSpacing}`);
      setShowLetterSpacingError(false);
    }
  };

  const handleBlurLineHeight = () => {
    if (lineHeightDisplay === '' || lineHeightDisplay === '-') {
      setLineHeightDisplay(`${lineHeight}`);
      setShowLineHeightError(false);
    }
  };

  // Keep display values in sync when resetting
  useEffect(() => {
    setLetterSpacingDisplay(`${letterSpacing}`);
  }, [letterSpacing]);

  useEffect(() => {
    setLineHeightDisplay(`${lineHeight}`);
  }, [lineHeight]);

  return (
    <header className={styles.header}>
      <LayoutProvider grid>
        <p className={cn('b2', styles.heading)}>{typestyle}</p>

        <div className={styles.config}>
          <div className={styles.settings}>
            <div className={styles.setting}>
              <p className="b2">Letter Spacing</p>

              <div
                className={cn('b2', styles.inputWrapper, styles.em, {
                  [styles.error]: showLetterSpacingError
                })}
              >
                <div aria-hidden className={styles.inputBackground} />

                <input
                  aria-label="Letter Spacing"
                  className={cn('b2', styles.input, {
                    [styles.error]: showLetterSpacingError
                  })}
                  type="number"
                  name="letterSpacing"
                  value={letterSpacingDisplay}
                  onChange={(event) =>
                    onLetterSpacingChange(event.target.value)
                  }
                  onBlur={handleBlurLetterSpacing}
                />

                <p
                  className={cn('caption', styles.inputError, {
                    [styles.visible]: showLetterSpacingError
                  })}
                >
                  Enter a value between -0.12 and 0.16
                </p>
              </div>
            </div>

            <div className={styles.setting}>
              <p className="b2">Line Height</p>

              <div
                className={cn('b2', styles.inputWrapper, {
                  [styles.error]: showLineHeightError
                })}
              >
                <div aria-hidden className={styles.inputBackground} />

                <input
                  className={cn('b2', styles.input, {
                    [styles.error]: showLineHeightError
                  })}
                  aria-label="Line height"
                  type="number"
                  name="lineHeight"
                  value={lineHeightDisplay}
                  onChange={(event) => onLineHeightChange(event.target.value)}
                  onBlur={handleBlurLineHeight}
                />

                <p
                  className={cn('caption', styles.inputError, {
                    [styles.visible]: showLineHeightError
                  })}
                >
                  Enter a value between 70 and 150
                </p>
              </div>
            </div>

            <div className={cn(styles.setting, styles.alignmentSetting)}>
              <p className="b2">Alignment</p>

              <div className={styles.alignmentButtons}>
                <button
                  type="button"
                  aria-label="Align left"
                  className={cn(styles.button, styles.alignmentButton, {
                    [styles.active]: alignment === 'left'
                  })}
                  onClick={() => setAlignment('left')}
                >
                  <SVG svg="alignLeft" />
                </button>

                {/* <button
                  type="button"
                  aria-label="Align center"
                  className={cn(styles.button, styles.alignmentButton, {
                    [styles.active]: alignment === 'center'
                  })}
                  onClick={() => setAlignment('center')}
                >
                  <SVG svg="alignCenter" />
                </button> */}
              </div>
            </div>
          </div>

          <div className={styles.resetButtonContainer}>
            <button
              type="button"
              aria-label="Reset text styles"
              className={cn(styles.button, styles.resetButton)}
              onClick={() => handleOnReset()}
            >
              <span className={cn('button', styles.resetButtonText)}>
                Reset All
              </span>

              <SVG svg="reset" />
            </button>
          </div>
        </div>
      </LayoutProvider>
    </header>
  );
};

export default Toolbar;
