import React from 'react';

import { ReactComponent as addUser } from './icons/add-user.svg';
import { ReactComponent as arrowDown } from './icons/arrow-down.svg';
import { ReactComponent as arrowDownLeft } from './icons/arrow-down-left.svg';
import { ReactComponent as arrowUp } from './icons/arrow-up.svg';
import { ReactComponent as arrowUpRight } from './icons/arrow-up-right.svg';
import { ReactComponent as brandiconSlash } from './icons/brandicon-slash.svg';
import { ReactComponent as brandicon } from './icons/brandicon.svg';
import { ReactComponent as calendar } from './icons/calendar.svg';
import { ReactComponent as cleaning } from './icons/cleaning.svg';
import { ReactComponent as connected } from './icons/connected.svg';
import { ReactComponent as cooling } from './icons/cooling.svg';
import { ReactComponent as envelope } from './icons/envelope.svg';
import { ReactComponent as eye } from './icons/eye.svg';
import { ReactComponent as help } from './icons/help.svg';
import { ReactComponent as history } from './icons/history.svg';
import { ReactComponent as home } from './icons/home.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as key } from './icons/key.svg';
import { ReactComponent as location } from './icons/location.svg';
import { ReactComponent as message } from './icons/message.svg';
import { ReactComponent as motionSensor } from './icons/motion-sensor.svg';
import { ReactComponent as phone } from './icons/phone.svg';
import { ReactComponent as searchMinus } from './icons/search-minus.svg';
import { ReactComponent as searchPlus } from './icons/search-plus.svg';
import { ReactComponent as settings } from './icons/settings.svg';
import { ReactComponent as smartphone } from './icons/smartphone.svg';
import { ReactComponent as temperature } from './icons/temperature.svg';
import { ReactComponent as threeDots } from './icons/three-dots.svg';
import { ReactComponent as toggleOff } from './icons/toggle-off.svg';
import { ReactComponent as toggleOn } from './icons/toggle-on.svg';
import { ReactComponent as userGroup } from './icons/user-group.svg';
import { ReactComponent as user } from './icons/user.svg';
import { ReactComponent as ventilation } from './icons/ventilation.svg';
import { ReactComponent as video } from './icons/video.svg';

export const brandIcons = Object.freeze({
  'Add User': addUser,
  'Arrow Down': arrowDown,
  'Arrow Down Left': arrowDownLeft,
  'Arrow Up': arrowUp,
  'Arrow Up Right': arrowUpRight,
  'Brandicon Slash': brandiconSlash,
  Brandicon: brandicon,
  Calendar: calendar,
  Cleaning: cleaning,
  Connected: connected,
  Cooling: cooling,
  Envelope: envelope,
  Eye: eye,
  Help: help,
  History: history,
  Home: home,
  Info: info,
  Key: key,
  Location: location,
  Message: message,
  'Motion Sensor': motionSensor,
  Phone: phone,
  'Search Minus': searchMinus,
  'Search Plus': searchPlus,
  Settings: settings,
  Smartphone: smartphone,
  Temperature: temperature,
  'Three Dots': threeDots,
  'Toggle Off': toggleOff,
  'Toggle On': toggleOn,
  'User Group': userGroup,
  User: user,
  Ventilation: ventilation,
  Video: video
});

export type TBrandIcon = keyof typeof brandIcons;

export interface IBrandIcon {
  icon: TBrandIcon;
  className?: string;
  style?: React.CSSProperties;
}

export const BrandIcon = ({ icon, ...props }: IBrandIcon) => {
  if (!brandIcons[icon]) return null;

  const SVGElement = brandIcons[icon];

  return <SVGElement {...props} aria-hidden />;
};
